.past-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.past {
  background-color: white;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 350px;
  margin: 1rem;
}

.past img {
  width: 100%;
  height: 250px;
}

.past-content {
  padding: 1rem;
}

.past-content h2 {
  margin-top: 0;
  font-size: 20px;
}

.past-content p {
  color: #555;
}

.past-content a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  transition: color 0.2s;
  outline: none;
  font-size: 14px !important;
}

.past-content a:hover {
  color:#912849;
}